import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  Plus, 
  Edit2, 
  Trash2, 
  AlertCircle,
  Check,
  ClipboardList,
  Info,
  GripVertical,
  MoreVertical,
  ChevronUp,
  ChevronDown,
  Flag,
  Type,
  Hash,
  ToggleLeft,
  Calendar,
  Clock,
  List
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { IntakeWorkflowStepModal } from '../components/IntakeWorkflowStepModal';

interface WorkflowStep {
  id: string;
  name: string;
  description: string;
  display_order: number;
  is_required: boolean;
  is_final_step: boolean;
  data_type: 'text' | 'number' | 'boolean' | 'date' | 'datetime' | 'select';
  select_options?: string[];
  created_at: string;
  updated_at: string;
}

export function IntakeWorkflowSteps() {
  const navigate = useNavigate();
  const { userRoles } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [workflowSteps, setWorkflowSteps] = useState<WorkflowStep[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [editingStep, setEditingStep] = useState<WorkflowStep | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!isAdmin) {
      navigate('/settings');
      return;
    }
    fetchWorkflowSteps();
  }, [isAdmin, navigate]);

  const fetchWorkflowSteps = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('intake_workflow_steps')
        .select('*')
        .order('display_order');

      if (error) throw error;
      setWorkflowSteps(data || []);
    } catch (error: any) {
      console.error('Error fetching workflow steps:', error);
      setError('Failed to load workflow steps');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (stepId: string) => {
    try {
      const { error } = await supabase
        .from('intake_workflow_steps')
        .delete()
        .eq('id', stepId);

      if (error) throw error;

      setSuccess('Workflow step successfully deleted');
      setShowDeleteConfirm(null);
      await fetchWorkflowSteps();

      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error deleting workflow step:', error);
      setError('Failed to delete workflow step');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleModalSuccess = async () => {
    await fetchWorkflowSteps();
    setSuccess(editingStep ? 'Workflow step updated successfully' : 'Workflow step created successfully');
    setTimeout(() => setSuccess(null), 3000);
  };

  const handleMoveStep = async (stepId: string, direction: 'up' | 'down') => {
    try {
      const currentIndex = workflowSteps.findIndex(step => step.id === stepId);
      if (currentIndex === -1) return;

      const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
      if (newIndex < 0 || newIndex >= workflowSteps.length) return;

      // Swap display_order values
      const updatedSteps = [...workflowSteps];
      const temp = updatedSteps[currentIndex].display_order;
      updatedSteps[currentIndex].display_order = updatedSteps[newIndex].display_order;
      updatedSteps[newIndex].display_order = temp;

      // Update in database
      const { error: error1 } = await supabase
        .from('intake_workflow_steps')
        .update({ display_order: updatedSteps[currentIndex].display_order })
        .eq('id', updatedSteps[currentIndex].id);

      const { error: error2 } = await supabase
        .from('intake_workflow_steps')
        .update({ display_order: updatedSteps[newIndex].display_order })
        .eq('id', updatedSteps[newIndex].id);

      if (error1 || error2) throw error1 || error2;

      // Refresh the list
      await fetchWorkflowSteps();
    } catch (error: any) {
      console.error('Error reordering workflow steps:', error);
      setError('Failed to reorder workflow steps');
      setTimeout(() => setError(null), 3000);
    }
  };

  const getDataTypeIcon = (dataType: string) => {
    switch (dataType) {
      case 'text':
        return <Type className="h-4 w-4 text-gray-500" />;
      case 'number':
        return <Hash className="h-4 w-4 text-gray-500" />;
      case 'boolean':
        return <ToggleLeft className="h-4 w-4 text-gray-500" />;
      case 'date':
        return <Calendar className="h-4 w-4 text-gray-500" />;
      case 'datetime':
        return <Clock className="h-4 w-4 text-gray-500" />;
      case 'select':
        return <List className="h-4 w-4 text-gray-500" />;
      default:
        return <Type className="h-4 w-4 text-gray-500" />;
    }
  };

  const getDataTypeLabel = (dataType: string) => {
    switch (dataType) {
      case 'text':
        return 'Text';
      case 'number':
        return 'Number';
      case 'boolean':
        return 'Yes/No';
      case 'date':
        return 'Date';
      case 'datetime':
        return 'Date & Time';
      case 'select':
        return 'Select';
      default:
        return 'Text';
    }
  };

  // If not admin, don't render the page
  if (!isAdmin) {
    return null;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/settings')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Intake Workflow Steps</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <ClipboardList className="h-5 w-5 text-green-600" />
                  <h3 className="ml-2 text-lg font-medium text-gray-900">
                    Workflow Steps
                  </h3>
                </div>
                <button
                  onClick={() => {
                    setEditingStep(null);
                    setShowModal(true);
                  }}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Step
                </button>
              </div>
            </div>

            {/* Messages */}
            {error && (
              <div className="px-6 py-4 bg-red-50">
                <div className="flex">
                  <AlertCircle className="h-5 w-5 text-red-400" />
                  <p className="ml-3 text-sm text-red-700">{error}</p>
                </div>
              </div>
            )}

            {success && (
              <div className="px-6 py-4 bg-green-50">
                <div className="flex">
                  <Check className="h-5 w-5 text-green-400" />
                  <p className="ml-3 text-sm text-green-700">{success}</p>
                </div>
              </div>
            )}

            {/* Workflow Steps List */}
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="w-8 px-6 py-3"></th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Step Name
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Description
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Data Type
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {loading ? (
                    <tr>
                      <td colSpan={6} className="px-6 py-4 text-center text-gray-500">
                        Loading workflow steps...
                      </td>
                    </tr>
                  ) : workflowSteps.length === 0 ? (
                    <tr>
                      <td colSpan={6} className="px-6 py-4 text-center text-gray-500">
                        <div className="flex flex-col items-center">
                          <Info className="h-12 w-12 text-gray-400 mb-2" />
                          <p>No workflow steps found</p>
                          <p className="text-sm text-gray-400 mt-1">
                            Click the "Add Step" button to create your first workflow step
                          </p>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    workflowSteps.map((step, index) => (
                      <tr key={step.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4">
                          <div className="flex flex-col">
                            <button
                              onClick={() => handleMoveStep(step.id, 'up')}
                              disabled={index === 0}
                              className={`p-1 ${index === 0 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:text-gray-700'}`}
                            >
                              <ChevronUp className="h-4 w-4" />
                            </button>
                            <button
                              onClick={() => handleMoveStep(step.id, 'down')}
                              disabled={index === workflowSteps.length - 1}
                              className={`p-1 ${index === workflowSteps.length - 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:text-gray-700'}`}
                            >
                              <ChevronDown className="h-4 w-4" />
                            </button>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {step.name}
                          {step.is_final_step && (
                            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                              <Flag className="h-3 w-3 mr-1" />
                              Final Step
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-500">
                          {step.description || '-'}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <div className="flex items-center">
                            {getDataTypeIcon(step.data_type)}
                            <span className="ml-2">{getDataTypeLabel(step.data_type)}</span>
                            {step.data_type === 'select' && step.select_options && step.select_options.length > 0 && (
                              <span className="ml-2 text-xs text-gray-400">
                                ({step.select_options.length} options)
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {step.is_required ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              Required
                            </span>
                          ) : (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                              Optional
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <div className="flex items-center justify-end space-x-3">
                            <button
                              onClick={() => {
                                setEditingStep(step);
                                setShowModal(true);
                              }}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <Edit2 className="h-5 w-5" />
                            </button>
                            {showDeleteConfirm === step.id ? (
                              <div className="flex items-center space-x-2">
                                <button
                                  onClick={() => handleDelete(step.id)}
                                  className="text-red-600 hover:text-red-900"
                                >
                                  Confirm
                                </button>
                                <button
                                  onClick={() => setShowDeleteConfirm(null)}
                                  className="text-gray-600 hover:text-gray-900"
                                >
                                  Cancel
                                </button>
                              </div>
                            ) : (
                              <button
                                onClick={() => setShowDeleteConfirm(step.id)}
                                className="text-red-600 hover:text-red-900"
                              >
                                <Trash2 className="h-5 w-5" />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Workflow Step Modal */}
      <IntakeWorkflowStepModal
        step={editingStep}
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setEditingStep(null);
        }}
        onSuccess={handleModalSuccess}
      />
    </div>
  );
}