import React from 'react';
import { X, AlertCircle, ArrowUpDown as ArrowsUpDown, UserPlus, Phone, Mail, MessageSquare } from 'lucide-react';

interface ZoomPatient {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  comments: string;
  selected: boolean;
}

interface PatientLead {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string | null;
  email: string | null;
  comments: string | null;
  mapped_patient_id: string | null;
  status: string;
  created_at: string;
  created_by: string;
  progress_percentage?: number;
}

interface MergePatientLeadModalProps {
  isOpen: boolean;
  onClose: () => void;
  newPatient: ZoomPatient;
  existingLead: PatientLead;
  onMerge: () => void;
  onCreateNew: () => void;
  onSkip?: () => void; // New prop for skipping
  currentIndex: number;
  totalCount: number;
}

export function MergePatientLeadModal({ 
  isOpen, 
  onClose, 
  newPatient, 
  existingLead, 
  onMerge, 
  onCreateNew,
  onSkip,
  currentIndex,
  totalCount
}: MergePatientLeadModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-3xl w-full">
        <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
          <div className="flex items-center">
            <ArrowsUpDown className="h-5 w-5 text-orange-500 mr-2" />
            <h3 className="text-lg font-medium text-gray-900">
              Potential Duplicate Found ({currentIndex} of {totalCount})
            </h3>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-6">
          <div className="mb-6 p-4 bg-yellow-50 rounded-md">
            <div className="flex">
              <AlertCircle className="h-5 w-5 text-yellow-400" />
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">Potential duplicate detected</h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    A patient with a similar name or contact information already exists in your leads. 
                    Would you like to merge the information, create a new lead, or skip this patient?
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* New Patient Data */}
            <div className="bg-green-50 p-4 rounded-lg border border-green-200">
              <h4 className="text-sm font-medium text-green-800 mb-3 flex items-center">
                <UserPlus className="h-4 w-4 mr-1" />
                New Patient Data
              </h4>
              <div className="space-y-3">
                <div>
                  <p className="text-xs text-gray-500">Name</p>
                  <p className="text-sm font-medium">{newPatient.firstName} {newPatient.lastName}</p>
                </div>
                <div>
                  <p className="text-xs text-gray-500">Phone</p>
                  <p className="text-sm">{newPatient.phoneNumber || '-'}</p>
                </div>
                <div>
                  <p className="text-xs text-gray-500">Email</p>
                  <p className="text-sm">{newPatient.email || '-'}</p>
                </div>
                <div>
                  <p className="text-xs text-gray-500">Comments</p>
                  <p className="text-sm line-clamp-3">{newPatient.comments || '-'}</p>
                </div>
              </div>
            </div>

            {/* Existing Patient Data */}
            <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
              <h4 className="text-sm font-medium text-blue-800 mb-3">Existing Patient Lead</h4>
              <div className="space-y-3">
                <div>
                  <p className="text-xs text-gray-500">Name</p>
                  <p className="text-sm font-medium">{existingLead.first_name} {existingLead.last_name}</p>
                </div>
                <div>
                  <p className="text-xs text-gray-500">Phone</p>
                  <p className="text-sm">{existingLead.phone_number || '-'}</p>
                </div>
                <div>
                  <p className="text-xs text-gray-500">Email</p>
                  <p className="text-sm">{existingLead.email || '-'}</p>
                </div>
                <div>
                  <p className="text-xs text-gray-500">Status</p>
                  <p className="text-sm">{existingLead.status}</p>
                </div>
                <div>
                  <p className="text-xs text-gray-500">Created</p>
                  <p className="text-sm">{new Date(existingLead.created_at).toLocaleDateString()}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Merge Preview */}
          <div className="mt-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
            <h4 className="text-sm font-medium text-gray-800 mb-3 flex items-center">
              <ArrowsUpDown className="h-4 w-4 mr-1" />
              Merge Preview
            </h4>
            <div className="space-y-3">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-xs text-gray-500">Name</p>
                  <p className="text-sm font-medium">{existingLead.first_name} {existingLead.last_name}</p>
                </div>
                <div>
                  <p className="text-xs text-gray-500">Phone</p>
                  <p className="text-sm">{newPatient.phoneNumber || existingLead.phone_number || '-'}</p>
                </div>
                <div>
                  <p className="text-xs text-gray-500">Email</p>
                  <p className="text-sm">{newPatient.email || existingLead.email || '-'}</p>
                </div>
                <div>
                  <p className="text-xs text-gray-500">Status</p>
                  <p className="text-sm">{existingLead.status}</p>
                </div>
              </div>
              <div>
                <p className="text-xs text-gray-500">Comments</p>
                <div className="text-sm mt-1 p-2 bg-white rounded border border-gray-200 max-h-24 overflow-y-auto">
                  {existingLead.comments && (
                    <div dangerouslySetInnerHTML={{ __html: existingLead.comments }}></div>
                  )}
                  {newPatient.comments && (
                    <>
                      {existingLead.comments && <hr className="my-2" />}
                      <div className="text-gray-700">
                        <strong>Additional Notes ({new Date().toLocaleDateString()}):</strong>
                        <p>{newPatient.comments}</p>
                      </div>
                    </>
                  )}
                  {!existingLead.comments && !newPatient.comments && <span className="text-gray-400">No comments</span>}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            {onSkip && (
              <button
                onClick={onSkip}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Skip This Lead
              </button>
            )}
            <button
              onClick={onCreateNew}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Create New Lead
            </button>
            <button
              onClick={onMerge}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
            >
              Merge with Existing
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}