import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import { ForcePasswordChange } from './components/ForcePasswordChange';
import { Login } from './pages/Login';
import { Dashboard } from './pages/Dashboard';
import { DashboardVertical } from './pages/DashboardVertical';
import { Settings } from './pages/Settings';
import { ImportData } from './pages/ImportData';
import { MembershipImport } from './pages/MembershipImport';
import { MembershipActivityImport } from './pages/MembershipActivityImport';
import { MembershipPaymentsImport } from './pages/MembershipPaymentsImport';
import { MembershipMapping } from './pages/MembershipMapping';
import { MembershipIssues } from './pages/MembershipIssues';
import { MembershipIncome } from './pages/MembershipIncome';
import { Patients } from './pages/Patients';
import { PatientDetails } from './pages/PatientDetails';
import { PatientServices } from './pages/PatientServices';
import { ProcedureCodes } from './pages/ProcedureCodes';
import { LoginActivity } from './pages/LoginActivity';
import { DataAnalytics } from './pages/DataAnalytics';
import { PopulationManagement } from './pages/PopulationManagement';
import { SqlInterface } from './pages/SqlInterface';
import { ProductCategories } from './pages/ProductCategories';
import { ProcedureCategories } from './pages/ProcedureCategories';
import { ServiceLevels } from './pages/ServiceLevels';
import { ProcedurePricing } from './pages/ProcedurePricing';
import { AdministeredMedicationsMapping } from './pages/AdministeredMedicationsMapping';
import { EnhancementManagement } from './pages/EnhancementManagement';
import { ProviderVisits } from './pages/ProviderVisits';
import { WeightLossData } from './pages/WeightLossData';
import { MessageBoard } from './pages/MessageBoard';
import { IntakeWorkflowSteps } from './pages/IntakeWorkflowSteps';
import { IntakeProgress } from './pages/IntakeProgress';
import { NewPatients } from './pages/NewPatients';
import { NotFound } from './pages/NotFound';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <ForcePasswordChange />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard-vertical"
            element={
              <ProtectedRoute>
                <DashboardVertical />
              </ProtectedRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/import-data"
            element={
              <ProtectedRoute>
                <ImportData />
              </ProtectedRoute>
            }
          />
          <Route
            path="/membership-import"
            element={
              <ProtectedRoute>
                <MembershipImport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/membership-activity-import"
            element={
              <ProtectedRoute>
                <MembershipActivityImport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/membership-payments-import"
            element={
              <ProtectedRoute>
                <MembershipPaymentsImport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/membership-mapping"
            element={
              <ProtectedRoute>
                <MembershipMapping />
              </ProtectedRoute>
            }
          />
          <Route
            path="/membership-issues"
            element={
              <ProtectedRoute>
                <MembershipIssues />
              </ProtectedRoute>
            }
          />
          <Route
            path="/membership-income"
            element={
              <ProtectedRoute>
                <MembershipIncome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/product-categories"
            element={
              <ProtectedRoute>
                <ProductCategories />
              </ProtectedRoute>
            }
          />
          <Route
            path="/procedure-categories"
            element={
              <ProtectedRoute>
                <ProcedureCategories />
              </ProtectedRoute>
            }
          />
          <Route
            path="/service-levels"
            element={
              <ProtectedRoute>
                <ServiceLevels />
              </ProtectedRoute>
            }
          />
          <Route
            path="/procedure-pricing"
            element={
              <ProtectedRoute>
                <ProcedurePricing />
              </ProtectedRoute>
            }
          />
          <Route
            path="/procedure-codes"
            element={
              <ProtectedRoute>
                <ProcedureCodes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/administered-medications-mapping"
            element={
              <ProtectedRoute>
                <AdministeredMedicationsMapping />
              </ProtectedRoute>
            }
          />
          <Route
            path="/login-activity"
            element={
              <ProtectedRoute>
                <LoginActivity />
              </ProtectedRoute>
            }
          />
          <Route
            path="/data-analytics"
            element={
              <ProtectedRoute>
                <DataAnalytics />
              </ProtectedRoute>
            }
          />
          <Route
            path="/population-management"
            element={
              <ProtectedRoute>
                <PopulationManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sql-interface"
            element={
              <ProtectedRoute>
                <SqlInterface />
              </ProtectedRoute>
            }
          />
          <Route
            path="/patients"
            element={
              <ProtectedRoute>
                <Patients />
              </ProtectedRoute>
            }
          />
          <Route
            path="/patients/:id"
            element={
              <ProtectedRoute>
                <PatientDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/patients/:id/services"
            element={
              <ProtectedRoute>
                <PatientServices />
              </ProtectedRoute>
            }
          />
          <Route
            path="/enhancement-management"
            element={
              <ProtectedRoute>
                <EnhancementManagement />
              </ProtectedRoute>
            }
          />
          <Route
            path="/provider-visits"
            element={
              <ProtectedRoute>
                <ProviderVisits />
              </ProtectedRoute>
            }
          />
          <Route
            path="/weight-loss-data"
            element={
              <ProtectedRoute>
                <WeightLossData />
              </ProtectedRoute>
            }
          />
          <Route
            path="/message-board"
            element={
              <ProtectedRoute>
                <MessageBoard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/intake-workflow-steps"
            element={
              <ProtectedRoute>
                <IntakeWorkflowSteps />
              </ProtectedRoute>
            }
          />
          <Route
            path="/intake-progress/:userId"
            element={
              <ProtectedRoute>
                <IntakeProgress />
              </ProtectedRoute>
            }
          />
          <Route
            path="/intake-progress"
            element={
              <ProtectedRoute>
                <IntakeProgress />
              </ProtectedRoute>
            }
          />
          <Route
            path="/new-patients"
            element={
              <ProtectedRoute>
                <NewPatients />
              </ProtectedRoute>
            }
          />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;