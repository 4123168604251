import React, { useState, useEffect, useRef } from 'react';
import { X, AlertCircle, Check, ToggleLeft, ToggleRight, Plus, Trash2 } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface WorkflowStep {
  id: string;
  name: string;
  description: string;
  display_order: number;
  is_required: boolean;
  is_final_step: boolean;
  data_type: 'text' | 'number' | 'boolean' | 'date' | 'datetime' | 'select';
  select_options?: string[];
  created_at: string;
  updated_at: string;
}

interface IntakeWorkflowStepModalProps {
  step?: WorkflowStep | null;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export function IntakeWorkflowStepModal({ step, isOpen, onClose, onSuccess }: IntakeWorkflowStepModalProps) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [isRequired, setIsRequired] = useState(false);
  const [isFinalStep, setIsFinalStep] = useState(false);
  const [dataType, setDataType] = useState<'text' | 'number' | 'boolean' | 'date' | 'datetime' | 'select'>('text');
  const [selectOptions, setSelectOptions] = useState<string[]>([]);
  const [newOption, setNewOption] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const newOptionInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (step) {
      setName(step.name);
      setDescription(step.description || '');
      setIsRequired(step.is_required);
      setIsFinalStep(step.is_final_step || false);
      setDataType(step.data_type || 'text');
      setSelectOptions(step.select_options || []);
    } else {
      setName('');
      setDescription('');
      setIsRequired(false);
      setIsFinalStep(false);
      setDataType('text');
      setSelectOptions([]);
    }
  }, [step]);

  const handleAddOption = () => {
    if (newOption.trim()) {
      setSelectOptions([...selectOptions, newOption.trim()]);
      setNewOption('');
      if (newOptionInputRef.current) {
        newOptionInputRef.current.focus();
      }
    }
  };

  const handleRemoveOption = (index: number) => {
    const newOptions = [...selectOptions];
    newOptions.splice(index, 1);
    setSelectOptions(newOptions);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      if (!name.trim()) {
        throw new Error('Step name is required');
      }

      // Validate select options if data type is select
      if (dataType === 'select' && selectOptions.length === 0) {
        throw new Error('Select type requires at least one option');
      }

      if (step?.id) {
        // Update existing step
        const { error: updateError } = await supabase
          .from('intake_workflow_steps')
          .update({
            name: name.trim(),
            description: description.trim() || null,
            is_required: isRequired,
            is_final_step: isFinalStep,
            data_type: dataType,
            select_options: dataType === 'select' ? selectOptions : null
          })
          .eq('id', step.id);

        if (updateError) throw updateError;
      } else {
        // Get the highest display_order
        const { data: orderData, error: orderError } = await supabase
          .from('intake_workflow_steps')
          .select('display_order')
          .order('display_order', { ascending: false })
          .limit(1);

        if (orderError) throw orderError;
        
        const nextOrder = orderData && orderData.length > 0 ? orderData[0].display_order + 1 : 0;

        // Create new step
        const { error: insertError } = await supabase
          .from('intake_workflow_steps')
          .insert({
            name: name.trim(),
            description: description.trim() || null,
            is_required: isRequired,
            is_final_step: isFinalStep,
            data_type: dataType,
            select_options: dataType === 'select' ? selectOptions : null,
            display_order: nextOrder
          });

        if (insertError) throw insertError;
      }

      onSuccess();
      onClose();
    } catch (error: any) {
      console.error('Error saving workflow step:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && dataType === 'select') {
      e.preventDefault();
      handleAddOption();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-md w-full">
        <div className="px-6 py-4 border-b border-gray-200 flex items-center justify-between">
          <h3 className="text-lg font-medium text-gray-900">
            {step?.id ? 'Edit Workflow Step' : 'Add New Workflow Step'}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          {error && (
            <div className="mb-4 p-4 bg-red-50 rounded-md">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <p className="ml-3 text-sm text-red-700">{error}</p>
              </div>
            </div>
          )}

          <div className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Step Name
              </label>
              <input
                ref={nameInputRef}
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                placeholder="Enter step name"
                required
              />
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                placeholder="Enter step description (optional)"
              />
            </div>

            <div>
              <label htmlFor="dataType" className="block text-sm font-medium text-gray-700">
                Data Type
              </label>
              <select
                id="dataType"
                value={dataType}
                onChange={(e) => setDataType(e.target.value as any)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option value="text">Text</option>
                <option value="number">Number</option>
                <option value="boolean">Yes/No</option>
                <option value="date">Date</option>
                <option value="datetime">Date & Time</option>
                <option value="select">Select from options</option>
              </select>
              <p className="mt-1 text-xs text-gray-500">
                Select the type of data this step will collect
              </p>
            </div>

            {dataType === 'select' && (
              <div>
                <label htmlFor="selectOptions" className="block text-sm font-medium text-gray-700">
                  Select Options
                </label>
                <div className="mt-1 flex">
                  <input
                    ref={newOptionInputRef}
                    type="text"
                    id="selectOptions"
                    value={newOption}
                    onChange={(e) => setNewOption(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="block w-full rounded-l-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                    placeholder="Add an option"
                  />
                  <button
                    type="button"
                    onClick={handleAddOption}
                    className="inline-flex items-center px-3 py-2 border border-l-0 border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100"
                  >
                    <Plus className="h-4 w-4" />
                  </button>
                </div>
                <div className="mt-2 space-y-2">
                  {selectOptions.length === 0 ? (
                    <p className="text-xs text-gray-500 italic">No options added yet</p>
                  ) : (
                    selectOptions.map((option, index) => (
                      <div key={index} className="flex items-center justify-between bg-gray-50 px-3 py-2 rounded-md">
                        <span className="text-sm text-gray-700">{option}</span>
                        <button
                          type="button"
                          onClick={() => handleRemoveOption(index)}
                          className="text-gray-400 hover:text-red-500"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}

            <div>
              <button
                type="button"
                onClick={() => setIsRequired(!isRequired)}
                className="inline-flex items-center space-x-2"
              >
                {isRequired ? (
                  <ToggleRight className="h-6 w-6 text-green-600" />
                ) : (
                  <ToggleLeft className="h-6 w-6 text-gray-400" />
                )}
                <span className={`text-sm font-medium ${
                  isRequired ? 'text-green-600' : 'text-gray-500'
                }`}>
                  Required Step
                </span>
              </button>
              <p className="mt-1 text-xs text-gray-500">
                Enable this option if this step must be completed before proceeding
              </p>
            </div>

            <div>
              <button
                type="button"
                onClick={() => setIsFinalStep(!isFinalStep)}
                className="inline-flex items-center space-x-2"
              >
                {isFinalStep ? (
                  <ToggleRight className="h-6 w-6 text-blue-600" />
                ) : (
                  <ToggleLeft className="h-6 w-6 text-gray-400" />
                )}
                <span className={`text-sm font-medium ${
                  isFinalStep ? 'text-blue-600' : 'text-gray-500'
                }`}>
                  Final Step
                </span>
              </button>
              <p className="mt-1 text-xs text-gray-500">
                Enable this option if this step marks the completion of the intake process
              </p>
            </div>
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading ? (
                <>
                  <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2" />
                  {step?.id ? 'Updating...' : 'Creating...'}
                </>
              ) : (
                step?.id ? 'Update Step' : 'Create Step'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}