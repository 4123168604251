import React, { useState, useEffect } from 'react';
import { CheckCircle, Circle, AlertCircle, Save, Info } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { StepDataInput } from './StepDataInput';

interface IntakeStep {
  step_id: string;
  step_name: string;
  step_description: string;
  step_order: number;
  is_required: boolean;
  is_final_step: boolean;
  data_type: 'text' | 'number' | 'boolean' | 'date' | 'datetime' | 'select';
  select_options?: string[];
  value?: any;
  completed_at?: string;
}

interface PatientLeadProgressTrackerProps {
  patientLeadId: string;
  onComplete?: () => void;
  onStepComplete?: (stepId: string, isComplete: boolean) => void;
}

export function PatientLeadProgressTracker({ patientLeadId, onComplete, onStepComplete }: PatientLeadProgressTrackerProps) {
  const [steps, setSteps] = useState<IntakeStep[]>([]);
  const [stepsData, setStepsData] = useState<Record<string, any>>({});
  const [originalStepsData, setOriginalStepsData] = useState<Record<string, any>>({});
  const [completedSteps, setCompletedSteps] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [savingSteps, setSavingSteps] = useState<Record<string, boolean>>({});
  const [hasChanges, setHasChanges] = useState(false);
  const [modifiedSteps, setModifiedSteps] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (patientLeadId) {
      fetchLeadProgress();
    }
  }, [patientLeadId]);

  // Check for changes when stepsData changes
  useEffect(() => {
    if (Object.keys(originalStepsData).length > 0) {
      const changesDetected = Object.keys(stepsData).some(stepId => {
        // Check if the value is different from the original
        return JSON.stringify(stepsData[stepId]) !== JSON.stringify(originalStepsData[stepId]);
      });
      setHasChanges(changesDetected);
    }
  }, [stepsData, originalStepsData]);

  // Auto-save when a step is modified
  useEffect(() => {
    const autoSaveStep = async (stepId: string) => {
      if (!stepId) return;
      
      try {
        await saveStep(stepId);
      } catch (error) {
        console.error(`Error auto-saving step ${stepId}:`, error);
      }
    };

    // Process each modified step
    modifiedSteps.forEach(stepId => {
      autoSaveStep(stepId);
    });
  }, [modifiedSteps]);

  const fetchLeadProgress = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Get progress data
      const { data, error } = await supabase.rpc('get_patient_lead_progress', {
        p_patient_lead_id: patientLeadId
      });

      if (error) throw error;
      
      // Process the data
      const stepsArray = data || [];
      const completedStepIds: string[] = [];
      const stepsDataRecord: Record<string, any> = {};
      
      stepsArray.forEach(step => {
        if (step.completed_at) {
          completedStepIds.push(step.step_id);
        }
        
        // Store the value for all steps, even if not completed
        if (step.value) {
          try {
            // Parse the JSON value if it's a string
            if (typeof step.value === 'string') {
              stepsDataRecord[step.step_id] = JSON.parse(step.value);
            } else {
              stepsDataRecord[step.step_id] = step.value;
            }
          } catch (e) {
            // If parsing fails, use the raw value
            stepsDataRecord[step.step_id] = step.value;
          }
        }
      });
      
      console.log('Fetched steps data:', stepsDataRecord);
      
      setSteps(stepsArray);
      setStepsData(stepsDataRecord);
      setOriginalStepsData({...stepsDataRecord}); // Keep a copy of the original data
      setCompletedSteps(completedStepIds);
      setModifiedSteps(new Set()); // Reset modified steps
      
      // Check if all required steps are complete
      const isComplete = await checkIfComplete();
      if (isComplete && onComplete) {
        onComplete();
      }
    } catch (error: any) {
      console.error('Error fetching lead progress:', error);
      setError('Failed to load progress data');
    } finally {
      setLoading(false);
    }
  };

  const checkIfComplete = async (): Promise<boolean> => {
    try {
      const { data, error } = await supabase.rpc('is_patient_lead_intake_complete', {
        p_patient_lead_id: patientLeadId
      });
      
      if (error) throw error;
      return !!data;
    } catch (error) {
      console.error('Error checking completion status:', error);
      return false;
    }
  };

  const handleStepDataChange = (stepId: string, value: any) => {
    setStepsData(prev => ({
      ...prev,
      [stepId]: value
    }));
    
    // Add this step to the modified steps set
    setModifiedSteps(prev => {
      const newSet = new Set(prev);
      newSet.add(stepId);
      return newSet;
    });
  };

  const saveStep = async (stepId: string) => {
    try {
      // Mark this step as saving
      setSavingSteps(prev => ({ ...prev, [stepId]: true }));
      
      // Save step data
      const { data, error } = await supabase.rpc('save_patient_lead_progress', {
        p_patient_lead_id: patientLeadId,
        p_step_id: stepId,
        p_value: stepsData[stepId] !== undefined ? JSON.stringify(stepsData[stepId]) : null
      });

      if (error) throw error;
      if (!data.success) throw new Error(data.error);

      // Update completed steps
      if (!completedSteps.includes(stepId)) {
        setCompletedSteps(prev => [...prev, stepId]);
      }

      // Update original data for this step
      setOriginalStepsData(prev => ({
        ...prev,
        [stepId]: stepsData[stepId]
      }));

      // Remove from modified steps
      setModifiedSteps(prev => {
        const newSet = new Set(prev);
        newSet.delete(stepId);
        return newSet;
      });

      if (onStepComplete) {
        onStepComplete(stepId, true);
      }

      // Check if all required steps are complete
      const isComplete = await checkIfComplete();
      if (isComplete && onComplete) {
        onComplete();
      }

      return true;
    } catch (error: any) {
      console.error('Error saving step data:', error);
      setError(`Failed to save step data: ${error.message}`);
      return false;
    } finally {
      // Mark this step as no longer saving
      setSavingSteps(prev => ({ ...prev, [stepId]: false }));
    }
  };

  const handleSaveAllChanges = async () => {
    try {
      setError(null);

      // Only save steps that have been modified
      const modifiedStepsArray = Array.from(modifiedSteps);
      
      // Save each modified step
      for (const stepId of modifiedStepsArray) {
        await saveStep(stepId);
      }

      setHasChanges(false);
    } catch (error: any) {
      console.error('Error saving all steps:', error);
      setError('Failed to save all changes');
    }
  };

  const getStepStatus = (stepId: string) => {
    if (completedSteps.includes(stepId)) {
      return 'completed';
    }
    
    return 'pending';
  };

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600"></div>
      </div>
    );
  }

  if (steps.length === 0) {
    return (
      <div className="text-center py-8 text-gray-500">
        No workflow steps have been defined yet
      </div>
    );
  }

  // Create pairs of steps for two-column layout
  const stepPairs = [];
  for (let i = 0; i < steps.length; i += 2) {
    stepPairs.push(steps.slice(i, i + 2));
  }

  return (
    <div className="space-y-4">
      {/* Error Message */}
      {error && (
        <div className="p-4 bg-red-50 rounded-md">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-400" />
            <p className="ml-3 text-sm text-red-700">{error}</p>
          </div>
        </div>
      )}

      {/* Progress Bar */}
      <div className="relative pt-1">
        <div className="flex mb-2 items-center justify-between">
          <div>
            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
              Progress
            </span>
          </div>
          <div className="text-right">
            <span className="text-xs font-semibold inline-block text-green-600">
              {Math.round((completedSteps.length / steps.length) * 100)}%
            </span>
          </div>
        </div>
        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
          <div 
            style={{ width: `${(completedSteps.length / steps.length) * 100}%` }} 
            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
          ></div>
        </div>
      </div>

      {/* Save Changes Button - Only show when there are changes */}
      {hasChanges && (
        <div className="sticky top-0 z-10 bg-white p-4 shadow-md rounded-lg border border-green-200">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <Info className="h-5 w-5 text-blue-500 mr-2" />
              <p className="text-sm text-gray-700">You have unsaved changes</p>
            </div>
            <button
              onClick={handleSaveAllChanges}
              disabled={modifiedSteps.size === 0}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Save className="h-4 w-4 mr-2" />
              Save All Changes
            </button>
          </div>
        </div>
      )}

      {/* Steps Grid - Two steps per row */}
      <div className="space-y-4">
        {stepPairs.map((pair, pairIndex) => (
          <div key={pairIndex} className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {pair.map((step) => {
              const status = getStepStatus(step.step_id);
              const isSaving = savingSteps[step.step_id];
              
              return (
                <div key={step.step_id} className="bg-white p-3 rounded-lg shadow-sm border border-gray-200">
                  <div className="flex items-center mb-2">
                    <div className="mr-2 flex-shrink-0">
                      {isSaving ? (
                        <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-green-600" />
                      ) : status === 'completed' ? (
                        <CheckCircle className="h-5 w-5 text-green-500" />
                      ) : (
                        <Circle className="h-5 w-5 text-gray-300" />
                      )}
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {step.step_name}
                        {step.is_required && (
                          <span className="ml-1 text-red-500">*</span>
                        )}
                        {step.is_final_step && (
                          <span className="ml-1 inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                            Final
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                  
                  {/* Input Field - Always visible but more compact */}
                  <div className="ml-7">
                    <StepDataInput
                      dataType={step.data_type as any}
                      value={stepsData[step.step_id]}
                      onChange={(value) => handleStepDataChange(step.step_id, value)}
                      selectOptions={step.select_options as string[]}
                      placeholder={`Enter ${step.step_name.toLowerCase()}`}
                      required={step.is_required}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
      
      {/* Save Changes Button (Bottom) - Only show when there are changes */}
      {hasChanges && (
        <div className="mt-6 flex justify-end">
          <button
            onClick={handleSaveAllChanges}
            disabled={modifiedSteps.size === 0}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Save className="h-4 w-4 mr-2" />
            Save All Changes
          </button>
        </div>
      )}
    </div>
  );
}