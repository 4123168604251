import React, { useState } from 'react';
import { Calendar, Clock } from 'lucide-react';

interface StepDataInputProps {
  dataType: 'text' | 'number' | 'boolean' | 'date' | 'datetime' | 'select';
  value: any;
  onChange: (value: any) => void;
  selectOptions?: string[];
  placeholder?: string;
  required?: boolean;
}

export function StepDataInput({ 
  dataType, 
  value, 
  onChange, 
  selectOptions = [], 
  placeholder = '', 
  required = false 
}: StepDataInputProps) {
  
  switch (dataType) {
    case 'text':
      return (
        <input
          type="text"
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
          placeholder={placeholder}
          required={required}
        />
      );
      
    case 'number':
      return (
        <input
          type="number"
          value={value || ''}
          onChange={(e) => onChange(e.target.valueAsNumber || null)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
          placeholder={placeholder}
          required={required}
        />
      );
      
    case 'boolean':
      return (
        <div className="mt-1 flex items-center space-x-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              checked={value === true}
              onChange={() => onChange(true)}
              className="form-radio h-4 w-4 text-green-600 border-gray-300 focus:ring-green-500"
              required={required}
            />
            <span className="ml-2 text-sm text-gray-700">Yes</span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              checked={value === false}
              onChange={() => onChange(false)}
              className="form-radio h-4 w-4 text-red-600 border-gray-300 focus:ring-red-500"
              required={required}
            />
            <span className="ml-2 text-sm text-gray-700">No</span>
          </label>
        </div>
      );
      
    case 'date':
      return (
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Calendar className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="date"
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
            required={required}
          />
        </div>
      );
      
    case 'datetime':
      return (
        <div className="mt-1 relative rounded-md shadow-sm">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Clock className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="datetime-local"
            value={value || ''}
            onChange={(e) => onChange(e.target.value)}
            className="pl-10 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
            required={required}
          />
        </div>
      );
      
    case 'select':
      return (
        <select
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
          required={required}
        >
          <option value="" disabled>{placeholder || 'Select an option'}</option>
          {selectOptions.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      );
      
    default:
      return (
        <input
          type="text"
          value={value || ''}
          onChange={(e) => onChange(e.target.value)}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
          placeholder={placeholder}
          required={required}
        />
      );
  }
}