import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  ArrowLeft, 
  Lock, 
  Upload, 
  Leaf,
  FileText,
  Users,
  Trash2,
  AlertCircle,
  Check,
  Plus,
  Edit2,
  LogOut,
  FileSpreadsheet,
  Link,
  BarChart,
  UserPlus,
  Terminal,
  Tags,
  FileCode2,
  Search,
  Clock,
  DollarSign,
  Syringe,
  Lightbulb,
  CreditCard,
  UserCog,
  Scale,
  Wallet,
  MessageSquare,
  ClipboardList,
  ClipboardCheck
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { UserModal } from '../components/UserModal';

interface User {
  id: string;
  email: string;
  created_at: string;
}

interface Role {
  id: string;
  name: string;
  description: string;
}

export function Settings() {
  const navigate = useNavigate();
  const { user, userRoles, signOut } = useAuth();
  const isAdmin = userRoles.includes('admin');
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>();

  useEffect(() => {
    if (isAdmin) {
      fetchUsers();
    }
  }, [isAdmin]);

  const fetchUsers = async () => {
    try {
      const { data, error } = await supabase.rpc('get_users');
      if (error) throw error;
      setUsers(data || []);
    } catch (error: any) {
      console.error('Error fetching users:', error);
      setError('Failed to load users');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (userId: string, userEmail: string) => {
    if (!confirm(`Are you sure you want to delete user ${userEmail}?`)) {
      return;
    }

    try {
      const { error } = await supabase.rpc('delete_user', {
        user_id: userId
      });

      if (error) throw error;

      setSuccess(`Successfully deleted user ${userEmail}`);
      fetchUsers();
    } catch (error: any) {
      console.error('Error deleting user:', error);
      setError(`Failed to delete user: ${error.message}`);
    }
  };

  const handleEditUser = (userId: string) => {
    setSelectedUserId(userId);
    setShowUserModal(true);
  };

  const handleAddUser = () => {
    setSelectedUserId(undefined);
    setShowUserModal(true);
  };

  const handleUserModalSuccess = () => {
    fetchUsers();
    setSuccess('User updated successfully');
  };

  const settingsSections = [
    {
      title: 'Import Data',
      icon: Upload,
      description: 'Import data from Athena (CSV files)',
      onClick: () => navigate('/import-data')
    },
    {
      title: 'Import Membership Information',
      icon: FileSpreadsheet,
      description: 'Import and process membership data',
      onClick: () => navigate('/membership-import')
    },
    {
      title: 'Import Membership Activity',
      icon: FileSpreadsheet,
      description: 'Import and process membership activity data',
      onClick: () => navigate('/membership-activity-import')
    },
    {
      title: 'Import Membership Payments',
      icon: CreditCard,
      description: 'Import and process membership payment data',
      onClick: () => navigate('/membership-payments-import')
    },
    {
      title: 'Membership Mapping',
      icon: Link,
      description: 'Map membership records to patients',
      onClick: () => navigate('/membership-mapping')
    },
    {
      title: 'Membership Income',
      icon: Wallet,
      description: 'View and analyze membership income',
      onClick: () => navigate('/membership-income'),
      adminOnly: true
    },
    {
      title: 'Product Categories',
      icon: Tags,
      description: 'Manage product sale categories',
      onClick: () => navigate('/product-categories'),
      adminOnly: true
    },
    {
      title: 'Procedure Categories',
      icon: FileCode2,
      description: 'Map procedure codes to categories',
      onClick: () => navigate('/procedure-categories'),
      adminOnly: true
    },
    {
      title: 'Service Levels',
      icon: Clock,
      description: 'Manage service level types',
      onClick: () => navigate('/service-levels'),
      adminOnly: true
    },
    {
      title: 'Procedure Pricing',
      icon: DollarSign,
      description: 'Set pricing for procedure codes',
      onClick: () => navigate('/procedure-pricing'),
      adminOnly: true
    },
    {
      title: 'Procedure Codes',
      icon: FileText,
      description: 'Update codes and medication details',
      onClick: () => navigate('/procedure-codes')
    },
    {
      title: 'Administered Medications Mapping',
      icon: Syringe,
      description: 'Map administered medications to procedure codes',
      onClick: () => navigate('/administered-medications-mapping'),
      adminOnly: true
    },
    {
      title: 'Population Management',
      icon: UserPlus,
      description: 'Manage patient population data',
      onClick: () => navigate('/population-management'),
      adminOnly: true
    },
    {
      title: 'Provider Visits',
      icon: UserCog,
      description: 'View provider visit statistics',
      onClick: () => navigate('/provider-visits'),
      adminOnly: true
    },
    {
      title: 'Weight Loss Data',
      icon: Scale,
      description: 'Analyze patient weight loss metrics',
      onClick: () => navigate('/weight-loss-data'),
      adminOnly: true
    },
    {
      title: 'Message Board',
      icon: MessageSquare,
      description: 'View and manage system messages',
      onClick: () => navigate('/message-board')
    },
    {
      title: 'Intake Workflow Steps',
      icon: ClipboardList,
      description: 'Configure lead intake workflow steps',
      onClick: () => navigate('/intake-workflow-steps'),
      adminOnly: true
    },
    {
      title: 'Intake Progress',
      icon: ClipboardCheck,
      description: 'View and manage intake progress',
      onClick: () => navigate('/intake-progress'),
      adminOnly: true
    },
    {
      title: 'Security',
      icon: Lock,
      description: 'Review login activity',
      onClick: () => navigate('/login-activity'),
      adminOnly: true
    },
    {
      title: 'Data Analytics',
      icon: BarChart,
      description: 'View data analytics and reports',
      onClick: () => navigate('/data-analytics'),
      adminOnly: true
    },
    {
      title: 'SQL Interface',
      icon: Terminal,
      description: 'Execute SQL queries and commands',
      onClick: () => navigate('/sql-interface'),
      adminOnly: true
    },
    {
      title: 'Enhancement Management',
      icon: Lightbulb,
      description: 'Review and manage enhancement suggestions',
      onClick: () => navigate('/enhancement-management'),
      adminOnly: true
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <nav className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Leaf className="h-6 w-6 text-green-600" />
              <div className="ml-2">
                <span className="text-xl font-semibold text-gray-900">THRIVE</span>
                <span className="ml-2 text-sm text-gray-500">Settings</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-700">{user?.email}</span>
              <div className="flex flex-wrap gap-2">
                {userRoles.map((role) => (
                  <span
                    key={role}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                  >
                    {role}
                  </span>
                ))}
              </div>
              <button
                onClick={() => signOut()}
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-8 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/dashboard')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Settings</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Settings Grid */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 mb-8">
            {settingsSections
              .filter(section => !section.adminOnly || isAdmin)
              .map((section) => (
                <div
                  key={section.title}
                  className="bg-white overflow-hidden shadow-lg rounded-lg relative"
                >
                  <div className="p-6">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <section.icon className="h-6 w-6 text-green-600" />
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium text-gray-900">
                          {section.title}
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          {section.description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-6 py-4">
                    <button
                      onClick={section.onClick}
                      className="text-sm font-medium text-green-600 hover:text-green-500"
                    >
                      Configure →
                    </button>
                  </div>
                </div>
              ))}
          </div>

          {/* User Management Section - Only visible to admins */}
          {isAdmin && (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="px-6 py-5 border-b border-gray-200">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Users className="h-5 w-5 text-green-600 mr-2" />
                    <h3 className="text-lg font-medium text-gray-900">
                      User Management
                    </h3>
                  </div>
                  <button
                    onClick={handleAddUser}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add User
                  </button>
                </div>
              </div>

              {/* Messages */}
              {error && (
                <div className="px-6 py-4 bg-red-50">
                  <div className="flex items-center">
                    <AlertCircle className="h-5 w-5 text-red-400" />
                    <p className="ml-3 text-sm text-red-700">{error}</p>
                  </div>
                </div>
              )}

              {success && (
                <div className="px-6 py-4 bg-green-50">
                  <div className="flex items-center">
                    <Check className="h-5 w-5 text-green-400" />
                    <p className="ml-3 text-sm text-green-700">{success}</p>
                  </div>
                </div>
              )}

              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Email
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Created At
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {loading ? (
                      <tr>
                        <td colSpan={3} className="px-6 py-4 text-center text-gray-500">
                          Loading users...
                        </td>
                      </tr>
                    ) : users.length === 0 ? (
                      <tr>
                        <td colSpan={3} className="px-6 py-4 text-center text-gray-500">
                          No users found
                        </td>
                      </tr>
                    ) : (
                      users.map((user) => (
                        <tr key={user.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {user.email}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {new Date(user.created_at).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-3">
                            <button
                              onClick={() => handleEditUser(user.id)}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <Edit2 className="h-5 w-5" />
                            </button>
                            <button
                              onClick={() => handleDeleteUser(user.id, user.email)}
                              className="text-red-600 hover:text-red-900"
                            >
                              <Trash2 className="h-5 w-5" />
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </main>

      {/* User Modal */}
      {showUserModal && (
        <UserModal
          userId={selectedUserId}
          onClose={() => setShowUserModal(false)}
          onSuccess={handleUserModalSuccess}
        />
      )}
    </div>
  );
}