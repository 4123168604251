import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Leaf, UserPlus, Users, Search, AlertCircle, Check, ClipboardList, PlusCircle, Phone, Mail, MessageSquare, Link, Flag, PieChart as ChartPie, Edit, FileSpreadsheet, UploadCloud, Table, CheckSquare, Square, X, ArrowUpDown as ArrowsUpDown, Trash2, LayoutGrid, LayoutList, Circle, CheckCircle } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';
import { PatientLeadProgressTracker } from '../components/intake/PatientLeadProgressTracker';
import { NewPatientLeadModal } from '../components/NewPatientLeadModal';
import { RichTextEditor } from '../components/intake/RichTextEditor';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { MergePatientLeadModal } from '../components/MergePatientLeadModal';
import { DeletePatientLeadModal } from '../components/DeletePatientLeadModal';

interface PatientLead {
  id: string;
  first_name: string;
  last_name: string;
  phone_number: string | null;
  email: string | null;
  comments: string | null;
  mapped_patient_id: string | null;
  status: string;
  created_at: string;
  created_by: string;
  progress_percentage?: number;
  deleted?: boolean;
}

interface ZoomPatient {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  comments: string;
  selected: boolean;
}

interface DuplicateMatch {
  newPatient: ZoomPatient;
  existingLead: PatientLead;
  action: 'merge' | 'create' | 'skip';
}

interface WorkflowStep {
  id: string;
  name: string;
  description: string;
  display_order: number;
  is_required: boolean;
  is_final_step: boolean;
  data_type: 'text' | 'number' | 'boolean' | 'date' | 'datetime' | 'select';
  select_options?: string[];
}

interface StepProgress {
  patient_lead_id: string;
  step_id: string;
  value: any;
  completed_at: string;
}

type ViewMode = 'grid' | 'table';

// ID of the intake workflow step to automatically mark as completed
const AUTO_COMPLETE_STEP_ID = '05f6be12-99dd-407f-bd9d-346993e24ed0';

export function NewPatients() {
  const navigate = useNavigate();
  const { user, userRoles } = useAuth();
  const isAdmin = userRoles.includes('admin');
  
  const [patientLeads, setPatientLeads] = useState<PatientLead[]>([]);
  const [filteredLeads, setFilteredLeads] = useState<PatientLead[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [selectedLead, setSelectedLead] = useState<PatientLead | null>(null);
  const [showNewLeadModal, setShowNewLeadModal] = useState(false);
  const [editingComments, setEditingComments] = useState(false);
  const [commentsContent, setCommentsContent] = useState('');
  const [savingComments, setSavingComments] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [processingImport, setProcessingImport] = useState(false);
  
  // CSV import states
  const [parsedPatients, setParsedPatients] = useState<ZoomPatient[]>([]);
  const [showImportTable, setShowImportTable] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  
  // Duplicate handling states
  const [duplicateMatches, setDuplicateMatches] = useState<DuplicateMatch[]>([]);
  const [currentDuplicateIndex, setCurrentDuplicateIndex] = useState<number>(-1);
  const [showMergeModal, setShowMergeModal] = useState(false);
  
  // Delete confirmation state
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletingLead, setDeletingLead] = useState(false);

  // Tab state
  const [activeTab, setActiveTab] = useState<'grid' | 'table'>('grid');
  
  // Workflow steps for table view
  const [workflowSteps, setWorkflowSteps] = useState<WorkflowStep[]>([]);
  const [stepsProgress, setStepsProgress] = useState<Record<string, Record<string, any>>>({});

  useEffect(() => {
    fetchPatientLeads();
    fetchWorkflowSteps();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      setFilteredLeads(
        patientLeads.filter(lead => 
          lead.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          lead.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (lead.email && lead.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (lead.phone_number && lead.phone_number.includes(searchTerm))
        )
      );
    } else {
      setFilteredLeads(patientLeads);
    }
  }, [searchTerm, patientLeads]);

  useEffect(() => {
    if (selectedLead) {
      setCommentsContent(selectedLead.comments || '');
    } else {
      setCommentsContent('');
      setEditingComments(false);
    }
  }, [selectedLead]);

  useEffect(() => {
    if (activeTab === 'table' && filteredLeads.length > 0) {
      fetchAllLeadsProgress();
    }
  }, [activeTab, filteredLeads]);

  const fetchPatientLeads = async () => {
    try {
      setLoading(true);
      
      // Get all patient leads with progress already calculated
      const { data, error } = await supabase.rpc('get_patient_leads_with_progress');

      if (error) throw error;
      
      setPatientLeads(data || []);
      setFilteredLeads(data || []);
    } catch (error: any) {
      console.error('Error fetching patient leads:', error);
      setError('Failed to load patient leads');
    } finally {
      setLoading(false);
    }
  };

  const fetchWorkflowSteps = async () => {
    try {
      const { data, error } = await supabase
        .from('intake_workflow_steps')
        .select('*')
        .order('display_order');

      if (error) throw error;
      setWorkflowSteps(data || []);
    } catch (error: any) {
      console.error('Error fetching workflow steps:', error);
      setError('Failed to load workflow steps');
    }
  };

  const fetchAllLeadsProgress = async () => {
    try {
      // Create a batch of promises to fetch progress for each lead
      const progressPromises = filteredLeads.map(async (lead) => {
        const { data, error } = await supabase
          .from('patient_lead_progress')
          .select('*')
          .eq('patient_lead_id', lead.id);
        
        if (error) throw error;
        
        // Convert array to record for easier access
        const progressRecord: Record<string, any> = {};
        data?.forEach((item) => {
          progressRecord[item.step_id] = item.value;
        });
        
        return { leadId: lead.id, progress: progressRecord };
      });
      
      // Wait for all promises to resolve
      const results = await Promise.all(progressPromises);
      
      // Convert results to a record
      const progressRecord: Record<string, Record<string, any>> = {};
      results.forEach(({ leadId, progress }) => {
        progressRecord[leadId] = progress;
      });
      
      setStepsProgress(progressRecord);
    } catch (error: any) {
      console.error('Error fetching all leads progress:', error);
    }
  };

  const handleLeadSelect = (lead: PatientLead) => {
    setSelectedLead(lead);
    setEditingComments(false);
  };

  const handleNewLeadSuccess = () => {
    setSuccess('New patient lead added successfully');
    fetchPatientLeads();
    setTimeout(() => setSuccess(null), 3000);
  };

  const handleStepComplete = async (stepId: string, isComplete: boolean) => {
    // Refresh the leads list to get updated progress percentages
    fetchPatientLeads();
  };

  const handleIntakeComplete = () => {
    setSuccess('Intake process completed successfully!');
    fetchPatientLeads(); // Refresh the leads to update status
    setTimeout(() => setSuccess(null), 3000);
  };

  const handleEditComments = () => {
    setEditingComments(true);
  };

  const handleSaveComments = async () => {
    if (!selectedLead) return;
    
    try {
      setSavingComments(true);
      
      const { error } = await supabase
        .from('patient_leads')
        .update({ comments: commentsContent })
        .eq('id', selectedLead.id);
      
      if (error) throw error;
      
      // Update the selected lead and the leads list
      const updatedLead = { ...selectedLead, comments: commentsContent };
      setSelectedLead(updatedLead);
      
      setPatientLeads(prev => 
        prev.map(lead => 
          lead.id === selectedLead.id ? updatedLead : lead
        )
      );
      
      setEditingComments(false);
      setSuccess('Comments updated successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error updating comments:', error);
      setError('Failed to update comments');
      setTimeout(() => setError(null), 3000);
    } finally {
      setSavingComments(false);
    }
  };

  const handleDeleteLead = () => {
    if (!selectedLead) return;
    setShowDeleteModal(true);
  };

  const confirmDeleteLead = async () => {
    if (!selectedLead) return;
    
    try {
      setDeletingLead(true);
      
      // Call the delete function
      const { data, error } = await supabase.rpc('delete_patient_lead', {
        p_lead_id: selectedLead.id
      });
      
      if (error) throw error;
      if (!data.success) throw new Error(data.error);
      
      // Update the UI
      setPatientLeads(prev => prev.filter(lead => lead.id !== selectedLead.id));
      setFilteredLeads(prev => prev.filter(lead => lead.id !== selectedLead.id));
      setSelectedLead(null);
      
      setSuccess('Patient lead deleted successfully');
      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error deleting patient lead:', error);
      setError(`Failed to delete patient lead: ${error.message}`);
      setTimeout(() => setError(null), 3000);
    } finally {
      setDeletingLead(false);
      setShowDeleteModal(false);
    }
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;
    
    const file = acceptedFiles[0];
    
    // Check if file is CSV
    if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
      setError('Please upload a CSV file');
      setTimeout(() => setError(null), 3000);
      return;
    }
    
    try {
      setUploadingFile(true);
      setError(null);
      
      // Read the file
      const text = await file.text();
      
      // Parse CSV
      Papa.parse(text, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          console.log('Parsed CSV:', results);
          
          // Process the data
          const patients: ZoomPatient[] = [];
          
          results.data.forEach((row: any) => {
            // Only include rows with "accepted" status
            if (row['Invitee Status']?.toLowerCase() === 'accepted') {
              // Extract the needed fields
              const patient: ZoomPatient = {
                firstName: row['Invitee First Name'] || '',
                lastName: row['Invitee Last Name'] || '',
                email: row['Invitee Email'] || '',
                phoneNumber: row['Response 1'] || '', // Phone number
                comments: row['Response 2'] || '', // Comments
                selected: true // Default to selected
              };
              
              patients.push(patient);
            }
          });
          
          if (patients.length === 0) {
            setError('No accepted appointments found in the CSV file');
            setTimeout(() => setError(null), 3000);
            return;
          }
          
          // Remove duplicates within the imported list
          const uniquePatients = removeDuplicatesFromImport(patients);
          
          // Set the parsed patients and show the import table
          setParsedPatients(uniquePatients);
          setShowImportTable(true);
          setSuccess(`Found ${uniquePatients.length} patients to import from "${file.name}"`);
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
          setError(`Error parsing CSV: ${error.message}`);
          setTimeout(() => setError(null), 3000);
        }
      });
      
    } catch (error: any) {
      console.error('Error processing CSV file:', error);
      setError(`Error processing file: ${error.message}`);
      setTimeout(() => setError(null), 3000);
    } finally {
      setUploadingFile(false);
    }
  }, []);

  // Remove duplicates from the imported list based on name
  const removeDuplicatesFromImport = (patients: ZoomPatient[]): ZoomPatient[] => {
    const uniquePatients: ZoomPatient[] = [];
    const nameMap = new Map<string, boolean>();
    
    patients.forEach(patient => {
      const fullName = `${patient.firstName.toLowerCase()} ${patient.lastName.toLowerCase()}`.trim();
      if (fullName && !nameMap.has(fullName)) {
        nameMap.set(fullName, true);
        uniquePatients.push(patient);
      }
    });
    
    return uniquePatients;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv']
    },
    maxFiles: 1
  });

  const handleToggleSelectAll = () => {
    setSelectAll(!selectAll);
    setParsedPatients(prev => 
      prev.map(patient => ({
        ...patient,
        selected: !selectAll
      }))
    );
  };

  const handleTogglePatient = (index: number) => {
    setParsedPatients(prev => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        selected: !updated[index].selected
      };
      return updated;
    });
  };

  // Check for duplicates in the database
  const findDuplicates = async (patients: ZoomPatient[]): Promise<DuplicateMatch[]> => {
    const duplicates: DuplicateMatch[] = [];
    const selectedPatients = patients.filter(p => p.selected);
    
    for (const patient of selectedPatients) {
      // Check for duplicates by name
      const { data, error } = await supabase.rpc('get_patient_leads');
      
      if (error) {
        console.error('Error checking for duplicates:', error);
        continue;
      }
      
      // Find potential matches by name
      const nameMatches = data.filter(lead => 
        lead.first_name.toLowerCase() === patient.firstName.toLowerCase() && 
        lead.last_name.toLowerCase() === patient.lastName.toLowerCase()
      );
      
      // Find potential matches by phone number if available
      const phoneMatches = patient.phoneNumber ? 
        data.filter(lead => 
          lead.phone_number && 
          lead.phone_number.replace(/\D/g, '') === patient.phoneNumber.replace(/\D/g, '')
        ) : [];
      
      // Find potential matches by email if available
      const emailMatches = patient.email ? 
        data.filter(lead => 
          lead.email && 
          lead.email.toLowerCase() === patient.email.toLowerCase()
        ) : [];
      
      // Combine all matches and remove duplicates
      const allMatches = [...nameMatches, ...phoneMatches, ...emailMatches];
      const uniqueMatches = allMatches.filter((match, index, self) => 
        index === self.findIndex(m => m.id === match.id)
      );
      
      if (uniqueMatches.length > 0) {
        // For now, just take the first match
        duplicates.push({
          newPatient: patient,
          existingLead: uniqueMatches[0],
          action: 'merge' // Default action
        });
      }
    }
    
    return duplicates;
  };

  const handleCreatePatientLeads = async () => {
    try {
      setProcessingImport(true);
      
      // Find duplicates
      const duplicates = await findDuplicates(parsedPatients);
      
      if (duplicates.length > 0) {
        // Set up duplicate resolution process
        setDuplicateMatches(duplicates);
        setCurrentDuplicateIndex(0);
        setShowMergeModal(true);
      } else {
        // No duplicates, proceed with import
        await importPatients();
      }
    } catch (error: any) {
      console.error('Error processing import:', error);
      setError(`Error processing import: ${error.message}`);
      setTimeout(() => setError(null), 3000);
    } finally {
      setProcessingImport(false);
    }
  };

  const handleMergeDecision = async (action: 'merge' | 'create' | 'skip') => {
    if (currentDuplicateIndex < 0 || currentDuplicateIndex >= duplicateMatches.length) {
      return;
    }
    
    // Update the action for the current duplicate
    const updatedMatches = [...duplicateMatches];
    updatedMatches[currentDuplicateIndex].action = action;
    setDuplicateMatches(updatedMatches);
    
    // Move to the next duplicate or finish
    if (currentDuplicateIndex < duplicateMatches.length - 1) {
      setCurrentDuplicateIndex(currentDuplicateIndex + 1);
    } else {
      // All duplicates have been resolved
      setShowMergeModal(false);
      await processImportWithDuplicateResolution();
    }
  };

  const processImportWithDuplicateResolution = async () => {
    try {
      setProcessingImport(true);
      
      // Get selected patients that aren't duplicates
      const selectedPatients = parsedPatients.filter(p => p.selected);
      const nonDuplicates = selectedPatients.filter(p => 
        !duplicateMatches.some(d => 
          d.newPatient.firstName === p.firstName && 
          d.newPatient.lastName === p.lastName
        )
      );
      
      // Process non-duplicates
      const newLeads = nonDuplicates.map(patient => ({
        first_name: patient.firstName,
        last_name: patient.lastName,
        phone_number: patient.phoneNumber || null,
        email: patient.email || null,
        comments: patient.comments || null,
        status: 'new',
        created_by: user?.id
      }));
      
      // Process duplicates based on action
      for (const match of duplicateMatches) {
        if (match.action === 'merge') {
          // Merge with existing lead
          await supabase
            .from('patient_leads')
            .update({
              phone_number: match.newPatient.phoneNumber || match.existingLead.phone_number,
              email: match.newPatient.email || match.existingLead.email,
              comments: combineComments(match.existingLead.comments, match.newPatient.comments),
              updated_at: new Date().toISOString()
            })
            .eq('id', match.existingLead.id);
        } else if (match.action === 'create') {
          // Create new lead
          newLeads.push({
            first_name: match.newPatient.firstName,
            last_name: match.newPatient.lastName,
            phone_number: match.newPatient.phoneNumber || null,
            email: match.newPatient.email || null,
            comments: match.newPatient.comments || null,
            status: 'new',
            created_by: user?.id
          });
        }
        // If action is 'skip', do nothing for this lead
      }
      
      // Insert all new leads
      if (newLeads.length > 0) {
        const { data: insertedLeads, error } = await supabase
          .from('patient_leads')
          .insert(newLeads)
          .select();
        
        if (error) throw error;
        
        // Auto-complete the specified step for all new leads
        if (insertedLeads && insertedLeads.length > 0) {
          await autoCompleteStepForLeads(insertedLeads.map(lead => lead.id));
        }
      }
      
      // Reset import state
      setShowImportTable(false);
      setParsedPatients([]);
      setDuplicateMatches([]);
      setCurrentDuplicateIndex(-1);
      
      // Refresh leads list
      await fetchPatientLeads();
      
      // Show success message
      const mergedCount = duplicateMatches.filter(d => d.action === 'merge').length;
      const newCount = newLeads.length;
      const skippedCount = duplicateMatches.filter(d => d.action === 'skip').length;
      
      setSuccess(`Import complete: ${mergedCount} leads merged, ${newCount} new leads created, ${skippedCount} leads skipped`);
      setTimeout(() => setSuccess(null), 5000);
      
    } catch (error: any) {
      console.error('Error processing import with duplicates:', error);
      setError(`Error processing import: ${error.message}`);
      setTimeout(() => setError(null), 3000);
    } finally {
      setProcessingImport(false);
    }
  };

  // Auto-complete the specified step for all new leads
  const autoCompleteStepForLeads = async (leadIds: string[]) => {
    try {
      // Create progress entries for each lead
      const progressEntries = leadIds.map(leadId => ({
        patient_lead_id: leadId,
        step_id: AUTO_COMPLETE_STEP_ID,
        value: true,
        completed_at: new Date().toISOString()
      }));
      
      // Insert all entries
      const { error } = await supabase
        .from('patient_lead_progress')
        .insert(progressEntries);
      
      if (error) {
        console.error('Error auto-completing step for leads:', error);
      }
    } catch (error) {
      console.error('Error in autoCompleteStepForLeads:', error);
    }
  };

  const combineComments = (existingComments: string | null, newComments: string | null): string => {
    if (!existingComments && !newComments) return '';
    if (!existingComments) return newComments || '';
    if (!newComments) return existingComments;
    
    return `${existingComments}<br><br><strong>Additional Notes (${new Date().toLocaleDateString()}):</strong><br>${newComments}`;
  };

  const importPatients = async () => {
    try {
      const selectedPatients = parsedPatients.filter(p => p.selected);
      
      if (selectedPatients.length === 0) {
        setError('No patients selected for import');
        setTimeout(() => setError(null), 3000);
        return;
      }
      
      // Create patient leads
      const patientLeads = selectedPatients.map(patient => ({
        first_name: patient.firstName,
        last_name: patient.lastName,
        phone_number: patient.phoneNumber || null,
        email: patient.email || null,
        comments: patient.comments || null,
        status: 'new',
        created_by: user?.id
      }));
      
      const { data: insertedLeads, error } = await supabase
        .from('patient_leads')
        .insert(patientLeads)
        .select();
      
      if (error) throw error;
      
      // Auto-complete the specified step for all new leads
      if (insertedLeads && insertedLeads.length > 0) {
        await autoCompleteStepForLeads(insertedLeads.map(lead => lead.id));
      }
      
      // Reset import state
      setShowImportTable(false);
      setParsedPatients([]);
      
      // Refresh leads list
      await fetchPatientLeads();
      
      // Show success message
      setSuccess(`Successfully imported ${patientLeads.length} patient leads`);
      setTimeout(() => setSuccess(null), 3000);
      
    } catch (error: any) {
      console.error('Error importing patients:', error);
      setError(`Error importing patients: ${error.message}`);
      setTimeout(() => setError(null), 3000);
    }
  };

  const getStatusBadgeColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'new':
        return 'bg-blue-100 text-blue-800';
      case 'contacted':
        return 'bg-yellow-100 text-yellow-800';
      case 'scheduled':
        return 'bg-purple-100 text-purple-800';
      case 'converted':
        return 'bg-green-100 text-green-800';
      case 'inactive':
        return 'bg-gray-100 text-gray-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const getProgressColor = (percentage: number) => {
    if (percentage >= 75) return 'text-green-600';
    if (percentage >= 50) return 'text-blue-600';
    if (percentage >= 25) return 'text-yellow-600';
    return 'text-gray-400';
  };

  const getStepValue = (leadId: string, stepId: string) => {
    if (!stepsProgress[leadId]) return null;
    return stepsProgress[leadId][stepId];
  };

  const isStepCompleted = (leadId: string, stepId: string) => {
    return getStepValue(leadId, stepId) !== undefined && getStepValue(leadId, stepId) !== null;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section with File Drop Zone */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate('/dashboard')}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  
                  {/* File Drop Zone */}
                  <div 
                    {...getRootProps()} 
                    className={`flex items-center px-4 py-2 rounded-lg cursor-pointer transition-colors duration-200 ${
                      isDragActive 
                        ? 'bg-white/30 text-white' 
                        : 'bg-white/10 text-white hover:bg-white/20'
                    }`}
                  >
                    <input {...getInputProps()} />
                    <UploadCloud className="h-5 w-5 mr-2" />
                    <span className="text-sm font-medium">
                      {isDragActive 
                        ? 'Drop patient list here...' 
                        : 'Drop patient list from Zoom here'
                      }
                    </span>
                  </div>
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">New Patients</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Messages */}
          {error && (
            <div className="mb-6 p-4 bg-red-50 rounded-lg">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <p className="ml-3 text-sm text-red-700">{error}</p>
              </div>
            </div>
          )}

          {success && (
            <div className="mb-6 p-4 bg-green-50 rounded-lg">
              <div className="flex">
                <Check className="h-5 w-5 text-green-400" />
                <p className="ml-3 text-sm text-green-700">{success}</p>
              </div>
            </div>
          )}

          {/* CSV Import Table */}
          {showImportTable && (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-6">
              <div className="px-6 py-5 border-b border-gray-200">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Table className="h-5 w-5 text-green-600" />
                    <h3 className="ml-2 text-lg font-medium text-gray-900">
                      Import Patients from Zoom
                    </h3>
                  </div>
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={handleToggleSelectAll}
                      className="inline-flex items-center text-sm text-gray-700"
                    >
                      {selectAll ? (
                        <CheckSquare className="h-4 w-4 mr-1 text-green-600" />
                      ) : (
                        <Square className="h-4 w-4 mr-1 text-gray-400" />
                      )}
                      {selectAll ? 'Deselect All' : 'Select All'}
                    </button>
                    <button
                      onClick={() => setShowImportTable(false)}
                      className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      <X className="h-4 w-4 mr-1" />
                      Cancel
                    </button>
                    <button
                      onClick={handleCreatePatientLeads}
                      className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                      disabled={parsedPatients.filter(p => p.selected).length === 0 || processingImport}
                    >
                      {processingImport ? (
                        <>
                          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-1" />
                          Processing...
                        </>
                      ) : (
                        <>
                          <UserPlus className="h-4 w-4 mr-1" />
                          Create {parsedPatients.filter(p => p.selected).length} Patient Leads
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Select
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Email
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Phone
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Comments
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {parsedPatients.map((patient, index) => (
                      <tr key={index} className={patient.selected ? 'bg-green-50' : ''}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <button
                            onClick={() => handleTogglePatient(index)}
                            className="text-gray-400 hover:text-gray-500"
                          >
                            {patient.selected ? (
                              <CheckSquare className="h-5 w-5 text-green-600" />
                            ) : (
                              <Square className="h-5 w-5" />
                            )}
                          </button>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {patient.firstName} {patient.lastName}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">{patient.email || '-'}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">{patient.phoneNumber || '-'}</div>
                        </td>
                        <td className="px-6 py-4">
                          <div className="text-sm text-gray-500 max-w-xs truncate">
                            {patient.comments || '-'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/* Tab Navigation */}
          <div className="bg-white shadow-sm rounded-lg mb-6 p-4">
            <div className="flex space-x-4 border-b border-gray-200">
              <button
                onClick={() => setActiveTab('grid')}
                className={`pb-4 px-1 ${
                  activeTab === 'grid'
                    ? 'border-b-2 border-green-500 text-green-600 font-medium'
                    : 'text-gray-500 hover:text-gray-700'
                } flex items-center`}
              >
                <LayoutGrid className="h-5 w-5 mr-2" />
                Detail View
              </button>
              <button
                onClick={() => setActiveTab('table')}
                className={`pb-4 px-1 ${
                  activeTab === 'table'
                    ? 'border-b-2 border-green-500 text-green-600 font-medium'
                    : 'text-gray-500 hover:text-gray-700'
                } flex items-center`}
              >
                <LayoutList className="h-5 w-5 mr-2" />
                Table View
              </button>
            </div>
          </div>

          {/* Grid View */}
          {activeTab === 'grid' && (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              {/* Left Column - Patient Leads List */}
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="px-6 py-5 border-b border-gray-200">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Users className="h-5 w-5 text-green-600" />
                      <h3 className="ml-2 text-lg font-medium text-gray-900">
                        Patient Leads
                      </h3>
                    </div>
                    <button
                      onClick={() => setShowNewLeadModal(true)}
                      className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                    >
                      <PlusCircle className="h-4 w-4 mr-1" />
                      Add New
                    </button>
                  </div>
                </div>

                {/* Search Bar */}
                <div className="px-6 py-4 border-b border-gray-200">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search by name, email, or phone..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="pl-10 pr-4 py-2 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                    />
                  </div>
                </div>

                {/* Patient Leads List */}
                <div className="overflow-y-auto" style={{ maxHeight: '600px' }}>
                  {loading ? (
                    <div className="flex justify-center py-8">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600"></div>
                    </div>
                  ) : filteredLeads.length === 0 ? (
                    <div className="text-center py-8 text-gray-500">
                      No patient leads found
                    </div>
                  ) : (
                    <ul className="divide-y divide-gray-200">
                      {filteredLeads.map((lead) => (
                        <li 
                          key={lead.id}
                          className={`hover:bg-gray-50 cursor-pointer ${selectedLead?.id === lead.id ? 'bg-green-50' : ''}`}
                          onClick={() => handleLeadSelect(lead)}
                        >
                          <div className="px-6 py-4">
                            <div className="flex items-center justify-between">
                              <div className="flex-1 min-w-0">
                                <div className="flex items-center justify-between">
                                  <p className="text-sm font-medium text-gray-900 truncate">
                                    {lead.first_name} {lead.last_name}
                                  </p>
                                  {typeof lead.progress_percentage === 'number' && (
                                    <div className="flex items-center ml-2">
                                      <ChartPie className={`h-4 w-4 ${getProgressColor(lead.progress_percentage)}`} />
                                      <span className={`ml-1 text-xs font-medium ${getProgressColor(lead.progress_percentage)}`}>
                                        {lead.progress_percentage}%
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="mt-1 flex flex-col space-y-1">
                                  {lead.phone_number && (
                                    <p className="text-xs text-gray-500 flex items-center">
                                      <Phone className="h-3 w-3 mr-1" />
                                      {lead.phone_number}
                                    </p>
                                  )}
                                  {lead.email && (
                                    <p className="text-xs text-gray-500 flex items-center">
                                      <Mail className="h-3 w-3 mr-1" />
                                      {lead.email}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div>
                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(lead.status)}`}>
                                  {lead.status}
                                </span>
                                {lead.mapped_patient_id && (
                                  <div className="mt-1 flex justify-end">
                                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-50 text-green-700">
                                      <Link className="h-3 w-3 mr-1" />
                                      Mapped
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              {/* Right Column - Lead Details & Intake Progress */}
              <div className="lg:col-span-2 bg-white shadow-lg rounded-lg overflow-hidden">
                <div className="px-6 py-5 border-b border-gray-200">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <ClipboardList className="h-5 w-5 text-green-600" />
                      <h3 className="ml-2 text-lg font-medium text-gray-900">
                        {selectedLead ? 'Lead Details & Intake Progress' : 'Lead Details'}
                      </h3>
                    </div>
                    {selectedLead && (
                      <button
                        onClick={handleDeleteLead}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
                      >
                        <Trash2 className="h-4 w-4 mr-1" />
                        Delete Lead
                      </button>
                    )}
                  </div>
                </div>

                <div className="p-6">
                  {selectedLead ? (
                    <div className="space-y-6">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                          <h4 className="text-sm font-medium text-gray-500">Contact Information</h4>
                          <div className="mt-2 space-y-2">
                            <p className="text-lg font-medium text-gray-900">
                              {selectedLead.first_name} {selectedLead.last_name}
                            </p>
                            {selectedLead.phone_number && (
                              <p className="text-sm text-gray-600 flex items-center">
                                <Phone className="h-4 w-4 mr-2 text-gray-400" />
                                {selectedLead.phone_number}
                              </p>
                            )}
                            {selectedLead.email && (
                              <p className="text-sm text-gray-600 flex items-center">
                                <Mail className="h-4 w-4 mr-2 text-gray-400" />
                                {selectedLead.email}
                              </p>
                            )}
                          </div>
                        </div>
                        <div>
                          <h4 className="text-sm font-medium text-gray-500">Status Information</h4>
                          <div className="mt-2 space-y-2">
                            <div className="flex items-center">
                              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(selectedLead.status)}`}>
                                {selectedLead.status}
                              </span>
                              {typeof selectedLead.progress_percentage === 'number' && (
                                <div className="flex items-center ml-3">
                                  <ChartPie className={`h-4 w-4 ${getProgressColor(selectedLead.progress_percentage)}`} />
                                  <span className={`ml-1 text-sm font-medium ${getProgressColor(selectedLead.progress_percentage)}`}>
                                    {selectedLead.progress_percentage}% Complete
                                  </span>
                                </div>
                              )}
                            </div>
                            <p className="text-sm text-gray-600">
                              Created: {new Date(selectedLead.created_at).toLocaleDateString()}
                            </p>
                            {selectedLead.mapped_patient_id && (
                              <p className="text-sm text-gray-600 flex items-center">
                                <Link className="h-4 w-4 mr-2 text-gray-400" />
                                Mapped to patient ID: {selectedLead.mapped_patient_id}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* Comments Section with Rich Text Editor */}
                      <div>
                        <div className="flex items-center justify-between mb-2">
                          <h4 className="text-sm font-medium text-gray-500">Comments</h4>
                          {!editingComments && (
                            <button
                              onClick={handleEditComments}
                              className="inline-flex items-center text-sm text-blue-600 hover:text-blue-800"
                            >
                              <Edit className="h-4 w-4 mr-1" />
                              Edit
                            </button>
                          )}
                        </div>
                        
                        {editingComments ? (
                          <div className="space-y-3">
                            <RichTextEditor
                              content={commentsContent || ''}
                              onChange={setCommentsContent}
                              placeholder="Enter comments about this patient lead..."
                            />
                            <div className="flex justify-end space-x-2">
                              <button
                                onClick={() => {
                                  setEditingComments(false);
                                  setCommentsContent(selectedLead.comments || '');
                                }}
                                className="px-3 py-1 text-sm text-gray-700 bg-gray-100 hover:bg-gray-200 rounded"
                                disabled={savingComments}
                              >
                                Cancel
                              </button>
                              <button
                                onClick={handleSaveComments}
                                className="px-3 py-1 text-sm text-white bg-green-600 hover:bg-green-700 rounded flex items-center"
                                disabled={savingComments}
                              >
                                {savingComments ? (
                                  <>
                                    <div className="animate-spin rounded-full h-3 w-3 border-b-2 border-white mr-1" />
                                    Saving...
                                  </>
                                ) : (
                                  <>
                                    <Check className="h-3 w-3 mr-1" />
                                    Save
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="mt-2 p-4 bg-gray-50 rounded-lg">
                            {selectedLead.comments ? (
                              <div 
                                className="prose prose-sm max-w-none"
                                dangerouslySetInnerHTML={{ __html: selectedLead.comments }}
                              />
                            ) : (
                              <div className="flex items-start text-gray-500 italic">
                                <MessageSquare className="h-5 w-5 text-gray-400 mt-0.5 mr-2" />
                                <p className="text-sm">No comments added yet</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      {/* Intake Progress Tracker */}
                      <div className="border-t border-gray-200 pt-6">
                        <div className="flex items-center mb-4">
                          <Flag className="h-5 w-5 text-green-600 mr-2" />
                          <h4 className="text-lg font-medium text-gray-900">Intake Progress</h4>
                        </div>
                        <PatientLeadProgressTracker 
                          patientLeadId={selectedLead.id}
                          onStepComplete={handleStepComplete}
                          onComplete={handleIntakeComplete}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="text-center py-12 text-gray-500">
                      <UserPlus className="h-12 w-12 mx-auto text-gray-400 mb-4" />
                      <p className="text-lg">Select a patient lead to view details</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* Table View */}
          {activeTab === 'table' && (
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="px-6 py-5 border-b border-gray-200">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Table className="h-5 w-5 text-green-600" />
                    <h3 className="ml-2 text-lg font-medium text-gray-900">
                      Patient Leads Progress
                    </h3>
                  </div>
                  <button
                    onClick={() => setShowNewLeadModal(true)}
                    className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                  >
                    <PlusCircle className="h-4 w-4 mr-1" />
                    Add New
                  </button>
                </div>
              </div>

              {/* Search Bar */}
              <div className="px-6 py-4 border-b border-gray-200">
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search by name, email, or phone..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="pl-10 pr-4 py-2 w-full rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                  />
                </div>
              </div>

              {/* Table with Rotated Headers */}
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky left-0 bg-gray-50 z-10">
                        Patient
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider sticky left-[200px] bg-gray-50 z-10">
                        Comments
                      </th>
                      {workflowSteps.map((step) => (
                        <th 
                          key={step.id} 
                          scope="col" 
                          className="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          style={{ minWidth: '80px' }}
                        >
                          <div className="h-24 flex items-center justify-center">
                            <div className="transform -rotate-90 origin-center whitespace-nowrap">
                              {step.name}
                              {step.is_required && <span className="text-red-500 ml-1">*</span>}
                            </div>
                          </div>
                        </th>
                      ))}
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Progress
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {loading ? (
                      <tr>
                        <td colSpan={workflowSteps.length + 5} className="px-6 py-4 text-center text-gray-500">
                          <div className="flex justify-center">
                            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600"></div>
                          </div>
                        </td>
                      </tr>
                    ) : filteredLeads.length === 0 ? (
                      <tr>
                        <td colSpan={workflowSteps.length + 5} className="px-6 py-4 text-center text-gray-500">
                          No patient leads found
                        </td>
                      </tr>
                    ) : (
                      filteredLeads.map((lead) => (
                        <tr 
                          key={lead.id} 
                          className={`hover:bg-gray-50 ${selectedLead?.id === lead.id ? 'bg-green-50' : ''}`}
                        >
                          <td className="px-6 py-4 whitespace-nowrap sticky left-0 bg-white z-10">
                            <div className="flex flex-col">
                              <div className="text-sm font-medium text-gray-900">
                                {lead.first_name} {lead.last_name}
                              </div>
                              <div className="text-xs text-gray-500 mt-1">
                                {lead.phone_number && (
                                  <div className="flex items-center">
                                    <Phone className="h-3 w-3 mr-1" />
                                    {lead.phone_number}
                                  </div>
                                )}
                                {lead.email && (
                                  <div className="flex items-center mt-1">
                                    <Mail className="h-3 w-3 mr-1" />
                                    {lead.email}
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 sticky left-[200px] bg-white z-10">
                            <div className="max-w-xs truncate text-sm text-gray-500">
                              {lead.comments ? (
                                <div dangerouslySetInnerHTML={{ __html: lead.comments }} />
                              ) : (
                                <span className="text-gray-400 italic">No comments</span>
                              )}
                            </div>
                          </td>
                          {workflowSteps.map((step) => (
                            <td key={step.id} className="px-2 py-4 text-center">
                              {isStepCompleted(lead.id, step.id) ? (
                                <div className="flex justify-center">
                                  <CheckCircle className="h-5 w-5 text-green-500" />
                                </div>
                              ) : (
                                <div className="flex justify-center">
                                  <Circle className="h-5 w-5 text-gray-300" />
                                </div>
                              )}
                            </td>
                          ))}
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(lead.status)}`}>
                              {lead.status}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {typeof lead.progress_percentage === 'number' && (
                              <div className="flex items-center">
                                <div className="w-24 bg-gray-200 rounded-full h-2.5 mr-2">
                                  <div 
                                    className="bg-green-600 h-2.5 rounded-full" 
                                    style={{ width: `${lead.progress_percentage}%` }}
                                  ></div>
                                </div>
                                <span className={`text-xs font-medium ${getProgressColor(lead.progress_percentage)}`}>
                                  {lead.progress_percentage}%
                                </span>
                              </div>
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <button
                              onClick={() => handleLeadSelect(lead)}
                              className="text-blue-600 hover:text-blue-900 mr-3"
                            >
                              View
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedLead(lead);
                                setShowDeleteModal(true);
                              }}
                              className="text-red-600 hover:text-red-900"
                            >
                              <Trash2 className="h-4 w-4" />
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* New Patient Lead Modal */}
      <NewPatientLeadModal
        isOpen={showNewLeadModal}
        onClose={() => setShowNewLeadModal(false)}
        onSuccess={handleNewLeadSuccess}
      />

      {/* Merge Patient Lead Modal */}
      {showMergeModal && currentDuplicateIndex >= 0 && currentDuplicateIndex < duplicateMatches.length && (
        <MergePatientLeadModal
          isOpen={showMergeModal}
          onClose={() => setShowMergeModal(false)}
          newPatient={duplicateMatches[currentDuplicateIndex].newPatient}
          existingLead={duplicateMatches[currentDuplicateIndex].existingLead}
          onMerge={() => handleMergeDecision('merge')}
          onCreateNew={() => handleMergeDecision('create')}
          onSkip={() => handleMergeDecision('skip')}
          currentIndex={currentDuplicateIndex + 1}
          totalCount={duplicateMatches.length}
        />
      )}

      {/* Delete Confirmation Modal */}
      {selectedLead && (
        <DeletePatientLeadModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={confirmDeleteLead}
          patientName={`${selectedLead.first_name} ${selectedLead.last_name}`}
          isDeleting={deletingLead}
        />
      )}
    </div>
  );
}