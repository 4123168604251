import React, { useState } from 'react';
import { Users, Settings, Clock, Lightbulb, UserPlus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { EnhancementSuggestionModal } from './EnhancementSuggestionModal';

interface StatCardsProps {
  patientCount: number;
}

export function StatCards({ patientCount }: StatCardsProps) {
  const navigate = useNavigate();
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);

  return (
    <>
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-5 mb-8">
        <div 
          className="bg-white overflow-hidden shadow-lg rounded-lg cursor-pointer hover:bg-gray-50 transition-colors duration-200"
          onClick={() => navigate('/patients', { state: { from: 'dashboard' } })}
        >
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Users className="h-6 w-6 text-green-600" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Total Patients
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    {patientCount}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div 
          className="bg-white overflow-hidden shadow-lg rounded-lg cursor-pointer hover:bg-gray-50 transition-colors duration-200"
          onClick={() => navigate('/new-patients')}
        >
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <UserPlus className="h-6 w-6 text-blue-600" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    New Patients
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    Intake Process
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div 
          className="bg-white overflow-hidden shadow-lg rounded-lg cursor-pointer hover:bg-gray-50 transition-colors duration-200"
          onClick={() => navigate('/membership-issues')}
        >
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Clock className="h-6 w-6 text-yellow-600" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Membership Issues
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    View All
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div 
          className="bg-white overflow-hidden shadow-lg rounded-lg cursor-pointer hover:bg-gray-50 transition-colors duration-200"
          onClick={() => navigate('/settings', { state: { from: 'dashboard' } })}
        >
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Settings className="h-6 w-6 text-gray-400" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Settings
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    Administration
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div 
          className="bg-white overflow-hidden shadow-lg rounded-lg cursor-pointer hover:bg-gray-50 transition-colors duration-200"
          onClick={() => setShowSuggestionModal(true)}
        >
          <div className="p-5">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Lightbulb className="h-6 w-6 text-purple-600" />
              </div>
              <div className="ml-5 w-0 flex-1">
                <dl>
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Future Enhancements
                  </dt>
                  <dd className="text-lg font-semibold text-gray-900">
                    Submit Ideas
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EnhancementSuggestionModal 
        isOpen={showSuggestionModal}
        onClose={() => setShowSuggestionModal(false)}
      />
    </>
  );
}