import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { 
  ArrowLeft, 
  Leaf, 
  CheckCircle, 
  Circle, 
  AlertCircle,
  Check,
  ClipboardList,
  Calendar,
  Clock,
  Type,
  Hash,
  ToggleLeft,
  List
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../lib/supabase';

interface IntakeStep {
  id: string;
  name: string;
  description: string;
  is_required: boolean;
  is_final_step: boolean;
  data_type: 'text' | 'number' | 'boolean' | 'date' | 'datetime' | 'select';
  select_options?: string[];
  display_order: number;
}

interface IntakeProgress {
  id: string;
  user_id: string;
  steps_completed: string[];
  current_step: string | null;
  is_complete: boolean;
  completed_at: string | null;
}

export function IntakeProgress() {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const { user, userRoles } = useAuth();
  const isAdmin = userRoles.includes('admin');
  
  const [steps, setSteps] = useState<IntakeStep[]>([]);
  const [progress, setProgress] = useState<IntakeProgress | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    if (!isAdmin && !userId) {
      navigate('/dashboard');
      return;
    }
    
    fetchWorkflowSteps();
    if (userId) {
      fetchUserProgress(userId);
    } else if (user) {
      fetchUserProgress(user.id);
    }
  }, [isAdmin, navigate, userId, user]);

  const fetchWorkflowSteps = async () => {
    try {
      const { data, error } = await supabase
        .from('intake_workflow_steps')
        .select('*')
        .order('display_order');

      if (error) throw error;
      setSteps(data || []);
    } catch (error: any) {
      console.error('Error fetching workflow steps:', error);
      setError('Failed to load workflow steps');
    }
  };

  const fetchUserProgress = async (id: string) => {
    try {
      const { data, error } = await supabase
        .from('intake_progress')
        .select('*')
        .eq('user_id', id)
        .single();

      if (error && error.code !== 'PGRST116') { // PGRST116 is "no rows returned"
        throw error;
      }

      if (data) {
        setProgress(data);
      } else {
        // Create a new progress record if none exists
        const { data: newProgress, error: createError } = await supabase
          .from('intake_progress')
          .insert({
            user_id: id,
            steps_completed: [],
            current_step: null,
            is_complete: false
          })
          .select()
          .single();

        if (createError) throw createError;
        setProgress(newProgress);
      }
    } catch (error: any) {
      console.error('Error fetching user progress:', error);
      setError('Failed to load user progress');
    } finally {
      setLoading(false);
    }
  };

  const getStepStatus = (stepId: string) => {
    if (!progress) return 'pending';
    
    if (progress.steps_completed.includes(stepId)) {
      return 'completed';
    }
    
    if (progress.current_step === stepId) {
      return 'current';
    }
    
    return 'pending';
  };

  const getDataTypeIcon = (dataType: string) => {
    switch (dataType) {
      case 'text':
        return <Type className="h-4 w-4" />;
      case 'number':
        return <Hash className="h-4 w-4" />;
      case 'boolean':
        return <ToggleLeft className="h-4 w-4" />;
      case 'date':
        return <Calendar className="h-4 w-4" />;
      case 'datetime':
        return <Clock className="h-4 w-4" />;
      case 'select':
        return <List className="h-4 w-4" />;
      default:
        return <Type className="h-4 w-4" />;
    }
  };

  // This is a placeholder component for now - we'll implement the actual progress tracking UI later
  return (
    <div className="min-h-screen bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-4 sm:px-0">
          {/* Header Section */}
          <div className="bg-white shadow-lg rounded-lg mb-6 overflow-hidden">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600 to-blue-600 opacity-90"></div>
              <div className="relative px-8 py-12">
                <div className="flex items-center justify-between">
                  <button
                    onClick={() => navigate(-1)}
                    className="text-white hover:text-green-100 transition-colors duration-200"
                  >
                    <ArrowLeft className="h-6 w-6" />
                  </button>
                  <Leaf className="h-12 w-12 text-white opacity-20" />
                </div>
                
                <div className="mt-4 text-center">
                  <h1 className="text-4xl font-bold text-white mb-2">THRIVE</h1>
                  <p className="text-green-100 text-lg mb-4">
                    Tracking Health and Reducing Illness Via Efficiency
                  </p>
                  <div className="w-16 h-1 bg-white/30 mx-auto rounded-full"></div>
                  <h2 className="text-2xl font-semibold text-white mt-4">Intake Progress</h2>
                </div>
              </div>
            </div>
          </div>

          {/* Messages */}
          {error && (
            <div className="mb-6 p-4 bg-red-50 rounded-lg">
              <div className="flex">
                <AlertCircle className="h-5 w-5 text-red-400" />
                <p className="ml-3 text-sm text-red-700">{error}</p>
              </div>
            </div>
          )}

          {success && (
            <div className="mb-6 p-4 bg-green-50 rounded-lg">
              <div className="flex">
                <Check className="h-5 w-5 text-green-400" />
                <p className="ml-3 text-sm text-green-700">{success}</p>
              </div>
            </div>
          )}

          {/* Progress Overview */}
          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-6">
            <div className="px-6 py-5 border-b border-gray-200">
              <div className="flex items-center">
                <ClipboardList className="h-5 w-5 text-green-600" />
                <h3 className="ml-2 text-lg font-medium text-gray-900">
                  Intake Progress
                </h3>
              </div>
            </div>
            <div className="p-6">
              {loading ? (
                <div className="flex justify-center py-8">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600"></div>
                </div>
              ) : steps.length === 0 ? (
                <div className="text-center py-8 text-gray-500">
                  No workflow steps have been defined yet
                </div>
              ) : (
                <div className="space-y-6">
                  {/* Progress Bar */}
                  <div className="relative pt-1">
                    <div className="flex mb-2 items-center justify-between">
                      <div>
                        <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-green-600 bg-green-200">
                          Progress
                        </span>
                      </div>
                      <div className="text-right">
                        <span className="text-xs font-semibold inline-block text-green-600">
                          {progress ? Math.round((progress.steps_completed.length / steps.length) * 100) : 0}%
                        </span>
                      </div>
                    </div>
                    <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-green-200">
                      <div 
                        style={{ width: `${progress ? (progress.steps_completed.length / steps.length) * 100 : 0}%` }} 
                        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
                      ></div>
                    </div>
                  </div>

                  {/* Steps List */}
                  <div className="flow-root">
                    <ul className="-mb-8">
                      {steps.map((step, stepIdx) => {
                        const status = getStepStatus(step.id);
                        return (
                          <li key={step.id}>
                            <div className="relative pb-8">
                              {stepIdx !== steps.length - 1 ? (
                                <span
                                  className={`absolute top-4 left-4 -ml-px h-full w-0.5 ${
                                    status === 'completed' ? 'bg-green-500' : 'bg-gray-200'
                                  }`}
                                  aria-hidden="true"
                                />
                              ) : null}
                              <div className="relative flex space-x-3">
                                <div>
                                  <span
                                    className={`h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white ${
                                      status === 'completed'
                                        ? 'bg-green-500'
                                        : status === 'current'
                                        ? 'bg-blue-500'
                                        : 'bg-gray-200'
                                    }`}
                                  >
                                    {status === 'completed' ? (
                                      <CheckCircle className="h-5 w-5 text-white" />
                                    ) : (
                                      <Circle className="h-5 w-5 text-white" />
                                    )}
                                  </span>
                                </div>
                                <div className="min-w-0 flex-1 pt-1.5">
                                  <div className="flex justify-between">
                                    <p className="text-sm font-medium text-gray-900">
                                      {step.name}
                                      {step.is_required && (
                                        <span className="ml-2 text-xs text-red-500">*</span>
                                      )}
                                      {step.is_final_step && (
                                        <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                          <Flag className="h-3 w-3 mr-1" />
                                          Final
                                        </span>
                                      )}
                                    </p>
                                    <div className="flex items-center text-sm text-gray-500">
                                      {getDataTypeIcon(step.data_type)}
                                      <span className="ml-1 capitalize">{step.data_type}</span>
                                    </div>
                                  </div>
                                  {step.description && (
                                    <p className="mt-1 text-sm text-gray-500">
                                      {step.description}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}